/*
* Common functions file*
*/
import CONSTANTS from '../constants/constants';

export const checkCampaignType = (campaign, type, cas)=>{
    console.log(campaign, type, cas)
    switch (cas) {
        case "B-Create":{
            console.log("C_1",(type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.template_type?.template_type === type)||campaign?.data?.template_type?.name === type)
            if((type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.template_type?.template_type === type)||campaign?.data?.template_type?.name === type)
            return true
            else return false
        }
        case "A-Create":{
            console.log("C_2",(type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.campaign_type?.template_type === type)|| campaign?.data?.campaign_type?.name === type)
            if((type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.campaign_type?.template_type === type)|| campaign?.data?.campaign_type?.name === type)
                return true
            else return false
        }
        case "B-Update":{
            console.log("C_3",(type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.template_type?.template_type === type)||campaign?.data?.template_type?.name === type)
            if((type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.template_type?.template_type === type)||campaign?.data?.template_type?.name === type)
                return true
            else return false
        }
        case "A-Update":{
            console.log("C_4",(type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.campaign_type?.template_type === type)|| campaign?.data?.campaign_type?.name === type)
            if((type == CONSTANTS.TEMPLATE_TYPES.CUSTOM_C && campaign?.data?.campaign_type?.template_type === type)|| campaign?.data?.campaign_type?.name === type)
                return true
            else return false
        }

    }
}
export const clearCusTempData = ()=>{
    localStorage.removeItem('gjsProject');
    localStorage.removeItem('templateObj');
    localStorage.removeItem('fonts');
}