import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import session from '../lib/session'
import PubSub from 'pubsub-js'
import Api from '../lib/request-service';
import { notification } from 'antd';
import CONSTANTS from '../../constants/constants';
export default function* UpdateAdminSaga() {
    yield takeLatest(types.UPDATE_ADMIN_REQUEST, UpdateAdminSagaApi);
}
function* UpdateAdminSagaApi(params) {
    let history = params.history;
    let loggedInUser = session.getUser();
    
    try {
        const response = yield Api.put(api_urls.ADMIN.UPDATE_ADMIN, params, true);
        if (response.status) {
            yield put({ type: types.UPDATE_ADMIN_SUCCESS, payload: response?.data });
            
            // notification success
            if (history !== undefined) {
                history.push(CONSTANTS.ROUTES.ADMIN.ADMIN);
            }

            if (loggedInUser?._id === response?.data?._id) {
                session.setUser(response?.data)
                PubSub.publishSync('storage', response?.data)
            }
            
            notification.success({
                placement: "topRight",
                message: response.message,
            });
        } else {
            yield put({ type: types.UPDATE_ADMIN_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
            });
        }
    } catch (error) {
        yield put({ type: types.UPDATE_ADMIN_FAILURE, error: error });
        // notification error error.message
    }
} 