import types from '../types/types';

//show bubble
export const showBubble = (params) => {
    return {
        type: types.SHOW_BUBBLE,
        payload: params
    };
};

export const clearBubble = () => {
    return {
        type: types.EMPTY_BUBBLE,
    };
};







