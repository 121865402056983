import types from '../types/types';
const initialState = {
    data: {
        // ppText: constants.ppText,
        // termsConditionsText: constants.termsConditionsText,
        rewardBox: [
            {
                type: 1,
                removeAble: false,
                heading: '', description: '', image: '', bgColor: '', bgImage: '',
                shareText: 'Share with friends', shareColor: ''
            }
        ]
    },
    loading: false
};

const update_campaign_states = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_CAMPAIGN_STATES:
            return {
                ...state,
                data: { ...state.data, ...action.payload }
            };
        case types.UPDATE_CAMPAIGN_STATES_REWARDS:
            return {
                ...state,
                data: { ...state.data, rewardBox: action.payload }
            };
        case types.RESET_UPDATE_CAMPAIGN_STATE:
            return {
                ...state,
                // data: {...initialState,...action.payload }
                data: {...action.payload, ...initialState.data }

            };
        case types.EMPTY_CAMPAIGN_STATES:
            return {
                data: {
                    // ppText: constants.ppText,
                    // termsConditionsText: constants.termsConditionsText,
                    rewardBox: [
                        {
                            type: 1,
                            removeAble: false,
                            heading: '', description: '', image: '', bgColor: '', bgImage: '',
                            shareText: 'Share with friends', shareColor: ''
                        }
                    ]
                }
            };
        case types.SIGN_IN_UPDATE:
            return {
                data: {
                    // ppText: constants.ppText,
                    // termsConditionsText: constants.termsConditionsText,
                    rewardBox: [
                        {
                            type: 1,
                            removeAble: false,
                            heading: '', description: '', image: '', bgColor: '', bgImage: '',
                            shareText: 'Share with friends', shareColor: ''
                        }
                    ]
                }
            };
        default:
            return state;
    }
};

export default update_campaign_states;
