import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';
import { checkCampaignType, clearCusTempData } from '../../utils';
import CONSTANTS from '../../constants/constants';

export default function* updateCampaign() {
    yield takeLatest(types.EDIT_CAMPAIGN_REQUEST, updateCampaignApi);
}
function* updateCampaignApi(params) {
    let param =  params.payload;
    let draft = params?.payload?.isDraft;
    if(draft)
        param = {
            id: params?.payload?.id,
            admin: params?.payload?.admin || '',
            publish: true,
            ...params.payload.data
        }
    delete param?.isDraft;
    try {
        const response = yield Api.put( draft ? api_urls.CAMPAIGN.UPDATE_TEMP_CAMPAIGN : api_urls.CAMPAIGN.UPDATE_CAMPAIGN, param, true);
        if (response.status) {
            if(checkCampaignType(response, CONSTANTS.TEMPLATE_TYPES.CUSTOM_C,"A-Update"))
                clearCusTempData()

            let body = {
                review_id: response?.data?.review_id,
                draft_id: response?.data?.draft_id
            };
            let res = yield Api.delete(api_urls.CAMPAIGN.DELETE_TEMP_CAMPAIGN,body, true);
            if(res.status)
                yield put({ type: types.EDIT_CAMPAIGN_SUCCESS, payload: response?.data });
            else{
                yield put({ type: types.EDIT_CAMPAIGN_SUCCESS, payload: response?.data });
            }
            // notification success
        } else {
            yield put({ type: types.EDIT_CAMPAIGN_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.EDIT_CAMPAIGN_FAILURE, error: error });
        // notification error error.message
    }
}
