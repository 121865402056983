import * as React from 'react';
import CONSTANTS from '../constants/constants'
const Signin = React.lazy(() => import('../components/Authentication/SignIn/SignIn'));
const ForgotPassword = React.lazy(() => import('../components/Authentication/ResetPassword/ForgotPassword'));
const ResetEmail = React.lazy(() => import('../components/Authentication/ResetPassword/ForgotResetEmail'));
const ResetPassword = React.lazy(() => import('../components/Authentication/ResetPassword/ResetPassword'));
const ChangeForgotPassword = React.lazy(() => import('../components/Authentication/ResetPassword/ChangeForgotPassword'));
const SessionExpired = React.lazy(() => import('../components/Authentication/SessionExpired'));

const publicRoutes = [
    { path: CONSTANTS.ROUTES.AUTH.LOG_IN, exact: true, name: 'Signin', component: Signin },
    { path: CONSTANTS.ROUTES.AUTH.FORGOT_PASSWORD, exact: true, name: 'Forgot Password', component: ForgotPassword },
    { path: CONSTANTS.ROUTES.AUTH.FORGOT_EMAIL, exact: true, name: 'Forgot Reset Email', component: ResetEmail },
    { path: CONSTANTS.ROUTES.AUTH.RESET_PASSWORD_OTP_VERIFICATION, exact: true, name: 'Reset Password', component: ResetPassword },
    { path: CONSTANTS.ROUTES.AUTH.CHANGE_PASSWORD_OTP_VERIFICATION, exact: true, name: 'Change Forgot Password', component: ChangeForgotPassword },
    { path: CONSTANTS.ROUTES.AUTH.SESSION_EXPIRED, exact: true, name: 'Session Expired', component: SessionExpired }
];
export default publicRoutes;