import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';

export default function* GetAllFansSaga() {
    yield takeLatest(types.GET_ALL_FANS_REQUEST, GetAllFansSagaApi);
}
function* GetAllFansSagaApi(params) {
    try {
        const response = yield Api.get(`${api_urls.ADMIN.GET_ALL_USERS_BY_TYPE}/FAN`, true);
        if (response.status) {
            yield put({ type: types.GET_ALL_FANS_SUCCESS, payload: response?.data});
            // notification success
        } else {
            yield put({ type: types.GET_ALL_FANS_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_ALL_FANS_FAILURE, error: error });
        // notification error error.message
    }
}
