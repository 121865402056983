import types from '../types/types';
const initialState = {
    data: null,
    loading: false
};

const edit_campaign_states = (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_CAMPAIGN_STATES:
            return {
                ...state,
                data: { ...state.data, ...action.payload }
            };
        case types.EDIT_CAMPAIGN_STATES_REWARDS:
            return {
                ...state,
                data: { ...state.data, rewardBox: action.payload }
            };
        case types.EMPTY_EDIT_CAMPAIGN_STATES:
            return {
                data: null
            };
        case types.SIGN_IN_UPDATE:
            return {
                data: null
            };
        default:
            return state;
    }
};

export default edit_campaign_states;
