import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* GetUserByIdSaga() {
    yield takeLatest(types.GET_USER_BY_ID_REQUEST, GetUserByIdSagaApi);
}
function* GetUserByIdSagaApi({params}) {
    const id = params;

    try {
        const response = yield Api.get(api_urls.ADMIN.GET_USER_BY_ID+`/${id}`, true);
        if (response.status) {
            yield put({ type: types.GET_USER_BY_ID_SUCCESS, payload: response?.data });
            // notification success
        } else {
            yield put({ type: types.GET_USER_BY_ID_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_USER_BY_ID_FAILURE, error: error });
        // notification error error.message
    }
}
