import {takeLatest, call, put} from 'redux-saga/effects';
import types from '../types/types';
import Api from '../lib/request-service';
import {api_urls, variables} from '../lib/urls';
import { checkCampaignType, clearCusTempData } from '../../utils';
import CONSTANTS from '../../constants/constants';

export default function* createCampaign() {
    yield takeLatest(types.CREATE_CAMPAIGN_REQUEST, createCampaignApi);
}
function* createCampaignApi(params) {
    let param = params.payload;
    try {
        const response = yield Api.post(`${api_urls.CAMPAIGN.CREATE_CAMPAIGN}`, param, true,false);
        if (response.status === true) {
            if(checkCampaignType(response, CONSTANTS.TEMPLATE_TYPES.CUSTOM_C,"A-Create"))
                clearCusTempData()

            let body = {
                review_id: response?.data?.review_id,
                draft_id: response?.data?.draft_id
            };
            let res = yield Api.delete(api_urls.CAMPAIGN.DELETE_TEMP_CAMPAIGN,body, true);
            if(res.status)
                yield put({type: types.CREATE_CAMPAIGN_SUCCESS, payload: response});
            else
                yield put({type: types.CREATE_CAMPAIGN_SUCCESS, payload: response});
        } else {
            yield put({type: types.CREATE_CAMPAIGN_FAILURE, error: response});
        }
    } catch (error) {
        yield put({type: types.CREATE_CAMPAIGN_FAILURE, error: error});
    }
}
