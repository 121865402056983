import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* getCampaignById() {
    yield takeLatest(types.GET_CAMPAIGN_EDIT_REQUEST, getCampaignByIdApi);
}
function* getCampaignByIdApi(params) {
    const name =  params.payload;
    try {
        const response = yield Api.get( params.draft ? api_urls.CAMPAIGN.GET_TEMP_CAMPAIGN+`/${name}` : api_urls.CAMPAIGN.GET_CAMPAIGN_BY_ID+`/${name}`, true);
        if (response.status) {
            yield put({ type: types.GET_CAMPAIGN_EDIT_SUCCESS, payload: response?.data });
            // notification success
        } else {
            yield put({ type: types.GET_CAMPAIGN_EDIT_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_CAMPAIGN_EDIT_FAILURE, error: error });
        // notification error error.message
    }
}
