// store without redux-tolkit.
import reducer from './reducer';
import { createStore } from 'redux';
import { applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import logger from "redux-logger"
// import rootReducer from "./reducer";
import rootSaga from "./sagas";
const sagaMiddleware = createSagaMiddleware();
const middleware = [];

if (process.env.REACT_APP_ENV == 'development' || process.env.REACT_APP_ENV == 'quality-assurance')
  middleware.push(sagaMiddleware, logger)
else
  middleware.push(sagaMiddleware)
  
// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',

  // Storage Method (React Native)
  storage: storage,

  // Whitelist (Save Specific Reducers)
  whitelist: ['authUserReducer','signInDomainReducer','update_campaign_states','show_bubble','edit_campaign_states','get_edit_campaign'],
};
const persistedReducer = persistReducer(persistConfig, reducer)
// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
// sagaMiddleware.run(rootSaga);

// export default store;
export const store = createStore(persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware)));
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

/*
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
*/
