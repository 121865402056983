import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* getCampaignByDomain() {
    yield takeLatest(types.GET_CAMPAIGN_REQUEST, getCampaignByDomainApi);
}
function* getCampaignByDomainApi(params) {
    // console.log(params,"sagas check");
    const name =  params.payload;
    try {
        const response = yield Api.get(name.includes('preview-') ? api_urls.CAMPAIGN.GET_TEMP_CAMPAIGN_BY_DOMAIN+`/${name}` : api_urls.CAMPAIGN.GET_CAMPAIGN_BY_DOMAIN+`/${name}`, true);
        if (response.status) {
            yield put({ type: types.GET_CAMPAIGN_SUCCESS, payload: response?.data });
            // notification success
        } else {
            yield put({ type: types.GET_CAMPAIGN_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_CAMPAIGN_FAILURE, error: error });
        // notification error error.message
    }
}
