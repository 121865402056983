import * as React from 'react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import publicRoutes from '../Routes/publicRoutes';
import 'antd/dist/antd.css';
import { useDispatch } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getToken, onMessageListener } from '../firebase';
import { showBubble } from '../store/actions/showBubble';
import { notification as popup } from 'antd';
import CONSTANTS from '../constants/constants'
const AdminLayout = lazy(() => import('./layout/AdminLayout'));
const App = () => {
    const data = localStorage.getItem('token');
    const location = useLocation()
    let dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        getToken(setTokenFound);
    }, []);

    onMessageListener().then(payload => {
        setNotification({ title: payload.notification.title, body: payload.notification.body });
        setShow(true);
        if (payload) {
            dispatch(showBubble(true));
            const args = {
                message: payload.notification.title,
                description: payload.notification.body,
                duration: 2,
                closeIcon: false
            };
            popup.open(args);
        }
    }).catch(err => console.log('failed: ', err));

    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
        content_type: 'image',
        content_id: 'P12453',
        items: [{ name: 'Kittens' }]
    });

    return (
        <>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Switch location={location} key={location.pathname}>
                        {!data ? publicRoutes.map((route, index) => {
                            return route.component ? (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props) => <route.component {...props} />}
                                />):null
                        }) : <AdminLayout />
                        }
                        <Route path="*" exact>
                            <Redirect to={data ? CONSTANTS.ROUTES.DASHBOARD.OVERVIEW : CONSTANTS.ROUTES.AUTH.LOG_IN} />
                        </Route>
                    </Switch>
                </Suspense>
            </ScrollToTop>
            <div className='backdrop' />
        </>
    );
};
export default App;
