import { takeLatest, all, call, put } from 'redux-saga/effects'
import types from '../types/types';
import {api_urls, variables} from '../lib/urls';
import session from '../lib/session'
import Api from '../lib/request-service';
import { notification } from 'antd';
import CONSTANTS from '../../constants/constants';
export default function* SignInSaga() {
    yield takeLatest(types.SIGN_IN_REQUEST, SignInSagaApi);
}
function* SignInSagaApi(params) {
    let param = params.payload;
    let history = params.history;

    try {
        const response = yield Api.post(`${api_urls.AUTH.SIGN_IN}`, param,true);
        if (response.status === true) {
            yield put({type: types.SIGN_IN_SUCCESS, payload: response});
            session.setToken(response?.data?.token)
            session.setUser(response?.data?.user)
            // notification success
            notification.success({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
            // redirect
            history.push({ path: CONSTANTS.ROUTES.DASHBOARD.OVERVIEW });
        } else {
            yield put({type: types.SIGN_IN_FAILURE, error: response});
            // not error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        }
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILURE, error: error});
        // not error error.message
        notification.error({
            placement: "topRight",
            // message: err.message,
            message: "Wrong Password or Email",
            maxCount: '1',
            duration: '1'
        });
    }
}
