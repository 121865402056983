// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken as token, onMessage } from "firebase/messaging";
import session from './store/lib/session';
import Api from './store/lib/request-service';
import { api_urls } from './store/lib/urls';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCkrMgypI8Du7Q4bJ3Xe-ruz9O7mCjkT2c",
    authDomain: "fandist-33495.firebaseapp.com",
    projectId: "fandist-33495",
    storageBucket: "fandist-33495.appspot.com",
    messagingSenderId: "867987371864",
    appId: "1:867987371864:web:530f4f12f324e7157f972d",
    measurementId: "G-4R3Y0EHCQL"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const updateUserFcmToken = async (user,token) =>{
  try{
      let body = {
          "id": user?._id,
          "data": {
              fcm_token: token
          },
          "user_type": user?.user_type[0]
      };
      let response = await Api.put(api_urls.USER.UPDATE_USER, body, true);
      if(response?.status){
          session.setUser(response?.data)
      }
  }  catch (e){
      console.log(e,"error in updating user fcm token")
  }
};

export const getToken = (setTokenFound) => {
    return token(messaging, { vapidKey: 'BLjqNapabY8wcyZ5WJkYep9UJ43mGUMGq7Q5S4WZL-2csD5UP0Q2guo0la-17id4roXZq4HatLLMeJxIcCctfmc' }).then(async(currentToken) => {
        if (currentToken) {
            let user = session.getUser();
            if(user && user?._id)
                await updateUserFcmToken(user,currentToken)
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

