import { takeLatest, put } from 'redux-saga/effects'
import types from '../types/types';
import {api_urls} from '../lib/urls';
import session from '../lib/session'
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* SignInDomainSaga() {
    yield takeLatest(types.SIGN_IN_REQUEST_DOMAIN, SignInDomainSagaApi);
}
function* SignInDomainSagaApi(params) {

    let param = params.payload;
    try {
        const response = yield Api.post(`${api_urls.USER.DOMAIN_USER_SIGN_IN}`, param, true);
        if (response.status === true) {
            yield put({type: types.SIGN_IN_SUCCESS_DOMAIN, payload: response});
            session.setToken(response?.data?.token);
            session.setUser(response?.data?.user);
            // redirect
            // notification success
            notification.success({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        } else {
            yield put({type: types.SIGN_IN_FAILURE_DOMAIN, error: response});
            // not error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        }
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILURE_DOMAIN, error: error});
        // not error error.message
        notification.error({
            placement: "topRight",
            // message: err.message,
            message: "Wrong Password or Email",
            maxCount: '1',
            duration: '1'
        });
    }
}
